<template>
  <div class="ys-cards__wrap">
    <div class="ys-cards">
      <b-card no-body>
        <b-card-header>
          <h4>Формирование документации</h4>
        </b-card-header>
        <div class="ml-3 mr-2 mb-2">
          <h6>Заголовок релиза: {{ version }}</h6>
          <div class="divider1 mt-2 mb-2"></div>
          <h6>Выбранные эпики:</h6>
          <!-- Todo List -->
          <vue-perfect-scrollbar :settings="perfectScrollbarSettings" class="ys-tasks__block">
            <draggable
              handle=".draggable-task-handle"
              tag="ul"
              class="ys-tasks__list"
              :list="epics"
              @end="onEnd"
            >
              <epic v-for="task in epics" :key="task.id" :task="task"> </epic>
            </draggable>
          </vue-perfect-scrollbar>
          <div class="divider1 mt-2 mb-2"></div>
          <h6>Выбранные задачи:</h6>
          <!-- Todo List -->
          <vue-perfect-scrollbar :settings="perfectScrollbarSettings" class="ys-tasks__block">
            <draggable
              handle=".draggable-task-handle"
              tag="ul"
              class="ys-tasks__list"
              :list="tasks"
            >
              <epic v-for="task in tasks" :key="task.id" :task="task"> </epic>
            </draggable>
          </vue-perfect-scrollbar>
          <template>
            <button
              type="button"
              class="btn btn-primary"
              @click="formSubmitBt"
              :disabled="submitDisable"
            >
              Сформировать документацию
            </button>
          </template>
        </div>
      </b-card>

      <b-sidebar
        id="sidebar-right"
        bg-variant="white"
        right
        backdrop
        shadow
        class="sidebar-task"
        no-header
        @hidden="hidden"
      >
        <template #default="{ hide }">
          <div class="ys-task__close" @click="hide">
            <feather-icon class="ml-1 cursor-pointer" icon="XIcon" size="18" />
          </div>
          <task-aside v-if="activeTask" :task="activeTask"></task-aside>
        </template>
      </b-sidebar>
    </div>
  </div>
</template>

<script>
import {
  BCard,
  BCardHeader,
  BCardBody,
  BButton,
  BDropdown,
  BDropdownItem,
  BDropdownGroup,
  BSidebar,
  VBToggle,
} from 'bootstrap-vue';
import Ripple from 'vue-ripple-directive';
import VuePerfectScrollbar from 'vue-perfect-scrollbar';
import draggable from 'vuedraggable';
import Epic from './Epic';
import TaskContent from '../task/TaskContent';
import FileSaver from 'file-saver';
import TaskAside from '../task/TaskAside';
import { mapGetters, mapState } from 'vuex';
import { docsApi, taskApi } from '@/api';
import { updateTask, backlogDnDEnd } from '@/mixins/task';
import axios from 'axios';

export default {
  name: 'DocGenerate',
  components: {
    BCard,
    BCardHeader,
    BCardBody,
    BButton,
    BDropdown,
    BDropdownItem,
    BDropdownGroup,
    draggable,
    VuePerfectScrollbar,
    Epic,
    BSidebar,
    TaskContent,
    TaskAside,
  },
  directives: {
    Ripple,
    'b-toggle': VBToggle,
  },
  props: {
    release: [Object],
    // sprints: [Array],
    // activeSprintStatus: [Number]
  },
  mixins: [updateTask, backlogDnDEnd],
  data: () => ({
    perfectScrollbarSettings: {
      maxScrollbarLength: 150,
    },
    submitDisable: false,
    tasks: [],
    epics: [],
  }),
  computed: {
    ...mapState('project', ['currentProject', 'activeTask']),
    version() {
      return this.release?.version || [];
    },
  },
  watch: {
    release: function (val) {
      this.tasks = val?.tasks?.map((item) => ({ ...item, checked: true }));
      this.epics = val?.epics?.map((item) => ({ ...item, checked: true }));
    },
  },
  methods: {
    onEnd(event) {},
    hidden() {
      this.$store.dispatch('project/updateActiveTask', null);
    },
    openTask() {
      // console.log('release:', this.release );
      /*taskApi.task(this.currentProject, task.number).then((res) => {
        this.$store.dispatch('project/updateActiveTask', res.data.task);
        this.$root.$emit('bv::toggle::collapse', 'sidebar-right');
      });*/
    },
    formSubmitBt() {
      const tasks = this.tasks?.filter((item) => item.checked).map((item) => item.key);
      const epics = this.epics?.filter((item) => item.checked).map((item) => item.key);
      const data = { tasks ,  epics};
      console.log('data:', data);
      // console.log('task:',  this.epics);
      docsApi.makeDoc(data, this.release.id).then((response) => {
        // console.log('res:', response);
        const fileNameHeader = 'x-suggested-filename';
        const fileName = response.headers[fileNameHeader];
        // console.log('fileName:', fileName);
        if (fileName) {
          FileSaver.saveAs(response.data, fileName);
        }
      });
    },
  },
};
</script>

<style scoped></style>
