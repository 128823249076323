<template>
  <div>
    <div class="slk-loader" v-if="isPending">
      <b-spinner label="Loading..." />
    </div>
    <!-- <backlog-nav
      @search="search"
      @filterAssignee="filterAssignee"
      :config="projectConfig"
      v-if="!activeEpic"
    ></backlog-nav>
     
   -->
    <div class="ys-backlog d-flex mt-2">
      <!--
      <backlog-tab v-if="isShowTab" @close="closeTabs" :tabIndex="tabIndex"></backlog-tab>
      
      <epic-tasks v-if="activeEpic"></epic-tasks>
      -->
      <div class="w-100">
        <doc-generate :release="release" />
      </div>
    </div>
  </div>
</template>

<script>
import {
  BSpinner,
  BModal,
  BAlert,
  BFormInput,
  BInputGroup,
  BInputGroupAppend,
} from 'bootstrap-vue';

import DocGenerate from './DocGenerate';
import { VBTooltip } from 'bootstrap-vue';
import ModalSprintForm from './ModalSprintForm';

import { mapActions, mapState, mapGetters } from 'vuex';
import { projectApi } from '@/api';

import axios from 'axios';

export default {
  name: 'ReleaseDoc',
  components: {
    DocGenerate,
    ModalSprintForm,
    BSpinner,
    BModal,
    BAlert,
    BFormInput,
    BInputGroup,
    BInputGroupAppend,
  },
  directives: {
    'b-tooltip': VBTooltip,
  },
  computed: {
    ...mapState('project', [
      'backlogTasks',
      'currentProject',
      'sprints',
      'activeSprintStatus',
      'activeEpic',
      'isPending',
      'releases',
    ]),
    ...mapGetters('project', ['sortSprints', 'getReleaseById']),
  },
  data: function () {
    return {
      isShowTab: false,
      filterTasks: null,
      filterSprints: null,
      projectConfig: null,
      editSprint: null,
      tabIndex: 1,

      release: null,
    };
  },
  methods: {
    ...mapActions('project', [
      'getBacklogTasks',
      'getSprints',
      'getActiveSprintStatus',
      'getReleases',
      'updatePending',
      'getRelease',
    ]),
    showTabs(number) {
      this.tabIndex = number;
      this.isShowTab = true;
    },
    closeTabs() {
      this.isShowTab = false;
      this.$store.dispatch('project/updateActiveEpic', null);
    },

    loadData() {
      this.getReleases();
    },
    getInitData() {
      // ---загрузка данных релиза в режиме редактирования---
      const id = this.$route.params.release;
      if (id) {
        // console.log('params:', this.$route.params);
        const release = this.getReleaseById(id);
        // console.log('release:', release);
        if (!release) {
          const callback = (val) => (this.release = val);
          this.getRelease({ id, callback });
        } else {
          this.release = release;
        }
      }
    },
  },
  watch: {},
  mounted() {
    // this.loadData();
    this.getInitData();
  },
};
</script>

<style scoped></style>
