<template>
  <li class="d-flex ys-tasks__item  ys-tasks__main align-items-center mb-1" :data-task="task.number">
    <div class="draggable-task-handle icon-drag">
      <feather-icon v-if="drag" icon="MoreVerticalIcon" class="d-inline" />
    </div>
    <div
      @click="openTask"
      class="ys-tasks__wrapper d-flex align-items-center justify-content-between ys-tasks__main"
    >
      <div class="align-items-center ys-tasks__left-container">
        <div class=" ys-tasks__title">{{ task.name }}</div>
        <!--
        <div class="ys-tasks__bage">
          <b-badge class="ml-0.3" :style="{ background: getColor(task.status) }">{{
            task.id
          }}</b-badge>
        </div>  -->
        
      </div>

      <div class="ys-tasks__right-container">
        
        <div class="ys-tasks__base-item">{{ task.key }}</div>
        
        <div class="checkbox ml-1">
          <input type="checkbox" id="task.key + 'chekbox'" v-model="task.checked" />
       </div>
        
      </div>
    </div>
  </li>
</template>

<script>
import { BBadge, BAvatar, VBTooltip } from 'bootstrap-vue';
import { colorTask, iconTask } from '@/helpers';
import { mapState } from 'vuex';

export default {
  name: 'Epic',
  components: {
    BBadge,
    BAvatar,
  },
  directives: {
    'b-tooltip': VBTooltip,
  },
  props: {
    task: [Object],
    drag: {
      type: Boolean,
      default: true,
    },
    link: {
      type: [Boolean, String],
      default: false,
    },
    lengthName: {
      type: [Boolean, String],
      default: false,
    },
    isLabel: {
      type: Boolean,
      default: true,
    },
  },
  data: function () {
    return {
      colorTask,
      iconTask,
      checked: true,
    };
  },
  methods: {
    openTask() {
      if (this.link) window.open(this.link, '_blank');
    },
    getColor(status) {
      const curStatus = this.colorStatus.filter((item) => item.id == status.id);
      return curStatus.length > 0 ? curStatus[0]['color'] : '#82868b';
    },
  },
  computed: {
    ...mapState('project', ['colorStatus']),
    sliceName() {
      if (this.lengthName) {
        return this.task.name.length > this.lengthName
          ? this.task.name.slice(0, this.lengthName) + '...'
          : this.task.name;
      } else {
        return this.task.name;
      }
    },
  },
};
</script>

<style scoped>
.ys-tasks__main {
  display: flex;
  min-width: 40rem;
}

.ys-tasks__left-container {
  display: flex;
  /* background-color: pink; */
  flex: 5 1 3rem;
}

.ys-tasks__right-container {
  display: flex;
  flex: 3 1 2rem;
  align-self: start;
  justify-content: center;
}

.ys-tasks__title {
  flex: 8 1 8rem;
}

.ys-tasks__bage {
  flex: 0.6 1 4rem;
  display: flex;
  justify-content: center;
  align-self: start;
  margin-left: 1px;
}

.ys-tasks__base-item {
  flex: 1;
  margin-left: 2px;
  /* background-color: yellow; */
  /* display: flex; */
  justify-content: center;
}

.checkbox {
  /* background-color: yellow; */
  display: flex; 
  justify-content: center;
}

.ys-tasks__epic {
  flex: 2 1 5rem;
  justify-content: start;
  word-wrap: break-word;
}

.icon-drag {
  padding-left: 8px;
  padding-right: 8px;
}
</style>
