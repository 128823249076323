<template>
  <b-modal
    id="modal-create-sprint"
    no-close-on-backdrop
    content-class="shadow"
    title="Создать/Редактировать спринт"
    @show="showModal"
    @hide="hideModal"
  >
    <validation-observer ref="createSprintForm" #default="{ invalid }">
      <b-form>
        <b-form-group label="Название спринта">
          <validation-provider #default="{ errors }" name="sprintName" rules="required">
            <b-form-input
              placeholder="Название спринта"
              v-model="sprintName"
              :disabled="disabledDate"
            />
            <small class="text-danger" v-if="errors[0]">Поле обязательно для заполнения</small>
          </validation-provider>
        </b-form-group>

        <b-form-group label="Планируемое начало спринта" v-if="!disabledDate">
          <validation-provider #default="{ errors }" name="sprintDateStart" rules="required">
            <flat-pickr v-model="sprintDateStart" class="form-control" :config="config" />
            <small class="text-danger" v-if="errors[0]">Поле обязательно для заполнения</small>
          </validation-provider>
        </b-form-group>

        <b-form-group label="Планируемое начало спринта" v-else>
          <b-form-input v-model="sprintDateStart" disabled />
        </b-form-group>

        <b-form-group label="Планируемое окончание спринта" v-if="!disabledDate">
          <validation-provider #default="{ errors }" name="sprintDateEnd" rules="required">
            <flat-pickr v-model="sprintDateEnd" class="form-control" :config="config" />
            <small class="text-danger" v-if="errors[0]">Поле обязательно для заполнения</small>
          </validation-provider>
        </b-form-group>

        <b-form-group label="Планируемое окончание спринта" v-else>
          <b-form-input v-model="sprintDateEnd" disabled />
        </b-form-group>

        <b-form-group label="Цели спринта">
          <validation-provider #default="{ errors }" name="sprintGoal" rules="required">
            <quill-editor v-model="sprintGoal" :options="editorOption" id="spint-goals">
            </quill-editor>
            <small class="text-danger" v-if="errors[0]">Поле обязательно для заполнения</small>
          </validation-provider>
        </b-form-group>
      </b-form>
    </validation-observer>
    <template #modal-footer>
      <button type="button" class="btn btn-secondary" @click="$bvModal.hide('modal-create-sprint')">
        Отмена
      </button>
      <button type="button" class="btn btn-primary" @click="formSubmit" :disabled="submitDisable">
        Сохранить
      </button>
    </template>
  </b-modal>
</template>

<script>
import {
  BModal,
  BForm,
  BInputGroup,
  BFormSelect,
  BFormGroup,
  BFormInput,
  BFormTextarea,
} from 'bootstrap-vue';
import flatPickr from 'vue-flatpickr-component';
import { projectApi } from '@/api';
import { ValidationProvider, ValidationObserver } from 'vee-validate';
import { required } from '@validations';
import { mapState, mapActions } from 'vuex';
import { getErrors } from '@/helpers/errors';

import moment from 'moment';
// eslint-disable-next-line
import 'quill/dist/quill.core.css';
// eslint-disable-next-line
import 'quill/dist/quill.snow.css';
// eslint-disable-next-line
import 'quill/dist/quill.bubble.css';
import { quillEditor } from 'vue-quill-editor';
import { Russian } from 'flatpickr/dist/l10n/ru';

export default {
  name: 'ModalSpintForm',
  components: {
    BModal,
    BForm,
    BInputGroup,
    BFormSelect,
    BFormGroup,
    BFormInput,
    BFormTextarea,
    flatPickr,
    ValidationObserver,
    ValidationProvider,
    quillEditor,
  },
  data: () => ({
    sprintName: '',
    sprintDateStart: null,
    sprintDateEnd: null,
    sprintGoal: '',
    submitDisable: false,
    editorOption: {
      placeholder: 'Введите текст...',
      modules: {
        toolbar: {
          container: [
            ['bold', 'italic', 'underline', 'strike'],
            ['link'],
            ['blockquote'],
            [{ header: 1 }, { header: 2 }],
            [{ list: 'ordered' }, { list: 'bullet' }],
            [{ script: 'sub' }, { script: 'super' }],
            [{ size: ['small', false, 'large', 'huge'] }],
            [{ color: [] }, { background: [] }],
            [{ align: [] }],
          ],
        },
      },
    },
    required,
    config: {
      wrap: true,
      altInput: true,
      dateFormat: 'd.m.Y H:i',
      // altFormat: 'Y-m-d H:i', // Время, отображаемое при выборе
      enableTime: true, // Выбираем час и минуту
      defaultHour: 8, // По умолчанию 8 баллов
      time_24hr: true, // Время 24 часа
      locale: Russian, // Китайский язык
    },
  }),
  props: {
    sprint: [Object],
  },
  methods: {
    ...mapActions('project', ['getSprints']),
    formSubmit() {
      this.$refs.createSprintForm.validate().then((success) => {
        if (success) {
          this.submitDisable = true;
          let sprint = this.disabledDate
            ? { description: this.sprintGoal }
            : {
                name: this.sprintName,
                description: this.sprintGoal,
                planned_at: moment(this.sprintDateStart, 'DD.MM.YYYY HH:mm').format(
                  'YYYY-MM-DD HH:mm',
                ),
                expired_at: moment(this.sprintDateEnd, 'DD.MM.YYYY HH:mm').format(
                  'YYYY-MM-DD HH:mm',
                ),
              };
          const request = this.openSprint
            ? projectApi.editSprint(this.openSprint.uuid, sprint)
            : projectApi.addSprint(this.currentProject, sprint);

          request
            .then((res) => {
              this.successToast();
              if (this.$route.params.hasOwnProperty('project')) {
                this.getSprints();
              }
              this.$bvModal.hide('modal-create-sprint');
            })
            .catch((error) => {
              if (error.response) {
                const errors = getErrors(error.response.data.errors);
                if (errors.general) {
                  this.errorToast(errors);
                }
              }
            })
            .then(() => {
              this.submitDisable = false;
            });
        }
      });
    },
    clearData() {
      this.sprintName = '';
      this.sprintDateStart = null;
      this.sprintDateEnd = null;
      this.sprintGoal = '';
    },
    showModal() {
      if (this.openSprint) {
        this.sprintName = this.openSprint.name;
        this.sprintDateStart = moment(this.openSprint.planned_at).format('DD.MM.YYYY HH:mm');
        this.sprintDateEnd = moment(this.openSprint.expired_at).format('DD.MM.YYYY HH:mm');
        this.sprintGoal = this.openSprint.description;
      } else {
        this.clearData();
      }
    },
    hideModal() {
      this.$store.dispatch('project/updateOpenSprint', null);
    },
    successToast() {
      this.$bvToast.toast('Спринт успешно создан/изменен', {
        title: this.$t('Сreating a sprint'),
        variant: 'success',
        solid: true,
      });
    },
    errorToast(errors) {
      this.$bvToast.toast(
        `Ошибка при создании задачи. ERROR: ${errors.general[0].status} ${errors.general[0].message}`,
        {
          title: this.$t('Сreating a sprint'),
          variant: 'danger',
          solid: true,
        },
      );
    },
  },
  computed: {
    ...mapState('project', ['currentProject', 'openSprint', 'activeSprintStatus']),
    disabledDate() {
      return this.openSprint !== null && this.openSprint.status_id == this.activeSprintStatus;
    },
  },
};
</script>

<style lang="scss">
@import '@core/scss/vue/libs/vue-flatpicker.scss';
</style>
